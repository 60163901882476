export class TeamDetailsModel {
  id: number
  name: string
  imageId: number
  imageUrl: string
  color: string
}

export class CreateTeamDetailsModel {
  name: string
  imageId?: number
  color?: string
}
