import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { QuestionTypesEnum } from '../common/Enums/QuestionTypesEnum';
import { FormArray } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { Subject, lastValueFrom, map, takeUntil, tap } from "rxjs";
import { PredictionPrizeSectionService } from "./wizzard-sections/prediction-prize-section/prize-section.service";
import { RoundTypesEnum } from '../common/Enums/RoundTypesEnum';
import { RoundStatusEnum } from '../common/Enums/RoundStatusEnum';
import { SaasSettingsService } from '../core/services/saas-settings.service';
import { QuestionTypeService } from "./common-wizzard-components/prediction-question/question-type.service";

export interface Question {
  type: FormControl<QuestionTypesEnum>,
  text?: FormControl<string>,
  stateCtrl: FormControl<string>,
  id?: FormControl<string | number>,
  sportEvent?: FormControl<string>,
  answerGroupId?: FormControl<number>,
  answerGroupId2?: FormControl<number>,
  additional?: FormGroup<any>,
  imageId?: FormControl<number>
  imageUrl?: FormControl<string>
  isMain?: FormControl<boolean>
}

export const MAX_LENGTH_ANSWERS_OPTION = 2;

export const MAX_LENGTH_ANSWERS_NOT_OPTION = 15;

export const MAX_LENGTH_ANSWERS_GRID = 30;

export const getValidateRangeValue = (
  group: FormGroup
): ValidationErrors | null => {
  if (!group?.get('minValue') ||
    !group?.get('maxValue') ||
    !group?.get('incrementValue')) return;

  const stringMinValue = group.get('minValue').value;
  const stringMaxValue = group.get('maxValue').value;
  const stringIncrementValue = group.get('incrementValue').value;

  const minValue = stringMinValue?.length ? +stringMinValue : null;
  const maxValue = stringMaxValue?.length ? +stringMaxValue : null;
  const incrementValue = stringIncrementValue?.length ? +stringIncrementValue : null;

  if (minValue === null && maxValue === null && incrementValue === null) return;

  if ((stringMinValue && stringMaxValue)) {
    if (minValue >= maxValue && !group.get('minValue').invalid) {
      group.get('minValue').setErrors({minValue: true});
    } else if (group.get('minValue').invalid && group.get('minValue').getError('minValue')) {
      removeError(group.get('minValue'), 'minValue');
      group.get('minValue').updateValueAndValidity({emitEvent: false});
    }
  }

  if (stringMinValue && stringMaxValue && stringIncrementValue) {
    if (incrementValue > maxValue - minValue && !group.get('incrementValue').invalid) {
        group.get('incrementValue').setErrors({incrementValue: true});
    } else if (
      incrementValue <= maxValue - minValue &&
      group.get('incrementValue').invalid &&
      group.get('incrementValue').getError('incrementValue')
    ) {
        removeError(group.get('incrementValue'), 'incrementValue');
        group.get('incrementValue').updateValueAndValidity({emitEvent: false});

    }

    if ((maxValue - (minValue + incrementValue)) % incrementValue !== 0 && !group.get('incrementValue').invalid) {
      group.get('incrementValue').setErrors({incrementValueAliquot: true});
    } else if ((maxValue - (minValue + incrementValue)) % incrementValue === 0 && group.get('incrementValue').invalid && group.get('incrementValue').getError('incrementValueAliquot')) {
        removeError(group.get('incrementValue'), 'incrementValueAliquot');
        group.get('incrementValue').updateValueAndValidity({emitEvent: false});

    }
  }

  return {};
}

const removeError = (control: AbstractControl, errorKey: string) => {
  const errors = control.errors;
  const newErrors = {};
  Object.keys(errors)
    .forEach(key => key !== errorKey ? newErrors[key] = errors[key] : null);
  control.setErrors(newErrors);
}

const autocompleteQuestionValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control?.get('id').value) {
    return {'required': true}
  }
  return null;
}

export const requiredOptionAnswersLength = 2;

const questionOptionsValidator: ValidatorFn = (
  control: FormArray
): ValidationErrors | null => {
  if (control?.controls?.length !== requiredOptionAnswersLength) {
    return {'requiredAnswersLength': {
      requiredLength: requiredOptionAnswersLength,
      currentLength: (control?.get('answers') as FormArray)?.length
    }}
  }
  return null;
}

export const requiredListGripAnswersLength = 3;

const questionListValidator: ValidatorFn = (
  control: FormArray
): ValidationErrors | null => {
  if (control?.controls?.length < requiredListGripAnswersLength) {
    return {'minAnswersLength': {
      minLength: requiredListGripAnswersLength,
      currentLength: (control?.get('answers') as FormArray)?.length
    }}
  }
  return null;
}

export const requiredListsAnswersLength = 2;

const getQuestionListsValidator: ValidatorFn = (
  control: FormArray
): ValidationErrors | null => {
  if (control?.controls?.length < requiredListsAnswersLength) {
    return {'minAnswersLength': {
      minLength: requiredListsAnswersLength,
      currentLength: (control?.get('answers') as FormArray)?.length
    }}
  }
  return null;
}

const questionSportEvent = () => {
  return (
    control: FormGroup
  ): ValidationErrors | null => {
    if (!control?.value?.id) {
      return {'required': true}
    }
    return null;
  }
}

@Injectable()
export class PredictionQuestionFormSectionService implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  private isTextForScoreRequired = true;

  constructor(
    private fb: NonNullableFormBuilder,
    private predictionPrizeSectionService: PredictionPrizeSectionService,
    private saasSettingsService: SaasSettingsService,
    private questionTypeService: QuestionTypeService
  ) {
    this.saasSettingsService.settingConfigArray$.pipe(
      map((settingConfigArray) => {
        const setting = settingConfigArray
          ?.find(item => item.key === 'is_question_text_not_required');
        if (setting) {
          return setting.value === 'false';
        }
        return true;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(isTextForScoreRequired => {
      this.isTextForScoreRequired = isTextForScoreRequired;
    })
  }

  build(isMultiEventRound) {
    return this.buildFormArray([this.buildForm(isMultiEventRound)]);
  }

  fillQuestionArray(questionForm: AbstractControl, questionList, isMultiEventRound: boolean, roundStatus: RoundStatusEnum) {
    questionList.forEach((question, index) => {
      if (!(questionForm.get('questions') as FormArray)?.controls?.[index]) {
        (questionForm.get('questions') as FormArray).insert(index, this.buildForm(isMultiEventRound));
      }
      const form: FormGroup = (questionForm.get('questions') as FormArray)?.controls?.[index] as FormGroup;
      this.fillQuestionForm(question, form, roundStatus);
    });

  }

  addAnswersToQuestionFromGroup(answers, questionType, form) {
    if (form.get("additional")) {
      form.removeControl('additional');
    }
    if (answers.length > 0) {
      form.addControl('additional', this.fillAnswers(questionType, answers));
    } else {
      form.addControl('additional', this.buildAdditionnalFormByType(questionType));
    }
  }

  buildFormArray(questionFormArray: AbstractControl[]): FormGroup<any> {
    return this.fb.group({
      questions: this.fb.array(questionFormArray)
    });
  }

  addTextQWithConditionToQuestionForm(form, isMultiEventRound) {
    if (this.isQuestionTextNotRequired(isMultiEventRound, form) && form.get('text')) {
      form.removeControl('text');
    } else if (!form.get('text') && !this.isQuestionTextNotRequired(isMultiEventRound, form)) {
      form.addControl('text', new FormControl('', [Validators.required, Validators.maxLength(255)]));
    }
    return form;
  }

  isQuestionTextNotRequired(isMultiEventRound, form) {
    return  isMultiEventRound && !this.isTextForScoreRequired && this.questionTypeService.isScoreQuestion(form.get('type').value);
  }

  buildForm(isMultiEventRound: boolean): FormGroup<Question> {
    const form = this.fb.group<Question>({
      type: new FormControl(QuestionTypesEnum.SCORE, [Validators.required]),
      stateCtrl: new FormControl(''),
      id: new FormControl(null),
      answerGroupId: new FormControl(undefined),
      answerGroupId2: new FormControl(undefined),
      imageId: new FormControl(null),
      imageUrl: new FormControl(null),
      isMain: new FormControl(false)
    });
    this.addTextQWithConditionToQuestionForm(form, isMultiEventRound);
    if (isMultiEventRound) {
      form.addControl('sportEvent', new FormControl(null, [questionSportEvent()]));
    }
    return form;
  }

  buildAutocompleteQuestion() {
    return this.fb.group({
      groupName: new FormControl(null),
      id: new FormControl(null),
      imageId: new FormControl(null),
      imageUrl: new FormControl(null),
      text: new FormControl(''),
      externalOutcomeId: new FormControl(null)
    }, {validators: autocompleteQuestionValidator})
  }

  buildRangeQuestion() {
    return this.fb.group({
      minValue: new FormControl(undefined, [Validators.max(9999), Validators.min(0), Validators.required]),
      maxValue: new FormControl(undefined, [Validators.max(9999), Validators.min(0), Validators.required]),
      displayValue: new FormControl(undefined, [Validators.maxLength(20),Validators.required]),
      incrementValue: new FormControl(undefined, [Validators.max(9999), Validators.min(0), Validators.required]),
    })
  }

  buildScorePlusQuestion() {
    return this.fb.group({
      maxScoreValue: new FormControl(undefined, [Validators.max(999), Validators.min(1), Validators.required]),
    })
  }

  isQuestionWithAnswerGroup(type) {
    return type === QuestionTypesEnum.LIST ||
      type === QuestionTypesEnum.LISTS ||
      type === QuestionTypesEnum.GRID||
      type === QuestionTypesEnum.OPTIONS;
  }

  buildAdditionnalFormByTypeForAnswerGroups(questionType: QuestionTypesEnum) {
    if (questionType === QuestionTypesEnum.LISTS) {
      // in group and in question we have the same form for answer but not for LISTS;
      return this.fb.group({
        answers: new FormArray([
          this.buildAutocompleteQuestion()
        ], getQuestionListsValidator)
      });
    } else {
      return this.buildAdditionnalFormByType(questionType);
    }
  }

  buildAdditionnalFormByType(questionType: QuestionTypesEnum) {
    switch(questionType) {
      case QuestionTypesEnum.LISTS:
        return this.fb.group({
          answers1st: new FormArray([
            this.buildAutocompleteQuestion()
          ], getQuestionListsValidator ),
          answers2st: new FormArray([
            this.buildAutocompleteQuestion()
          ], getQuestionListsValidator )
        });
      case QuestionTypesEnum.GRID:
      case QuestionTypesEnum.LIST:
        return this.fb.group({
          answers: new FormArray([
            this.buildAutocompleteQuestion()
          ], questionListValidator)
        });
      case QuestionTypesEnum.SCORE:
        return this.fb.group({});
      case QuestionTypesEnum.SCORE_PLUS:
        return this.fb.group({
          maxScoreValue: new FormControl(null, [Validators.max(999), Validators.min(1), Validators.required]),
        })
      case QuestionTypesEnum.OPTIONS:
        return this.fb.group({
          answers: new FormArray([
            this.buildAutocompleteQuestion()
          ], questionOptionsValidator)
        });
      case QuestionTypesEnum.RANGE:
        //eslint-disable-next-line
        const form = this.buildRangeQuestion();
        form.setValidators(getValidateRangeValue);
        return form;
    }
  }

  fillAnswersForAnswersGroup(questionType, answers) {
    if (questionType === QuestionTypesEnum.LISTS) {
      // in group and in question we have the same form for answer but not for LISTS;
      return this.fb.group({
        answers: new FormArray(
          answers.map((answer) => {
            const answerForm = this.buildAutocompleteQuestion();
            answerForm.get('groupName').patchValue(answer['groupName']);
            answerForm.get('id').patchValue(answer['id']);
            answerForm.get('imageId').patchValue(answer['imageId']);
            answerForm.get('imageUrl').patchValue(answer['imageUrl']);
            answerForm.get('text').patchValue(answer['text']);
            answerForm.get('externalOutcomeId').patchValue(answer['externalOutcomeId']);
            return answerForm;
          }), getQuestionListsValidator
        )
      });
    } else {
      return this.fillAnswers(questionType, answers);
    }
  }

  fillAnswersForLists(answers) {
    const answersArray = answers.reduce((acc, answer) => {
      const answerForm = this.buildAutocompleteQuestion();
      answerForm.get('groupName').patchValue(answer['groupName']);
      answerForm.get('id').patchValue(answer['id']);
      answerForm.get('imageId').patchValue(answer['imageId']);
      answerForm.get('imageUrl').patchValue(answer['imageUrl']);
      answerForm.get('text').patchValue(answer['text']);
      acc.push(answerForm);
      return acc;
    }, []);
    return new FormArray(answersArray, getQuestionListsValidator);
  }


  fillAnswers(questionType, answers, answerGroups = null) {
    switch(questionType) {
      case QuestionTypesEnum.LISTS:
        //eslint-disable-next-line
        const answers1stArrray = answers.reduce((acc, answer) => {
          if (answer.groupName === 'group_0' || (answer.answerGroupId && answer.answerGroupId === answerGroups?.answerGroup?.id)) {
            const answerForm = this.buildAutocompleteQuestion();
            answerForm.get('groupName').patchValue(answer['groupName']);
            answerForm.get('id').patchValue(answer['id']);
            answerForm.get('imageId').patchValue(answer['imageId']);
            answerForm.get('imageUrl').patchValue(answer['imageUrl']);
            answerForm.get('text').patchValue(answer['text']);
            acc.push(answerForm);
          }
          return acc;
        }, []);
        //eslint-disable-next-line
        const answers2stArrray = answers.reduce((acc, answer) => {
          if (answer.groupName === 'group_1' ||( answer.answerGroupId && answer.answerGroupId === answerGroups?.answerGroup2?.id)) {
            const answerForm = this.buildAutocompleteQuestion();
            answerForm.get('groupName').patchValue(answer['groupName']);
            answerForm.get('id').patchValue(answer['id']);
            answerForm.get('imageId').patchValue(answer['imageId']);
            answerForm.get('imageUrl').patchValue(answer['imageUrl']);
            answerForm.get('text').patchValue(answer['text']);
            acc.push(answerForm);
          }
          return acc;
        }, []);
        return this.fb.group({
          answers1st: new FormArray(
            answers1stArrray?.length ?
              answers1stArrray :
              [this.buildAutocompleteQuestion()]
            , getQuestionListsValidator
          ),
          answers2st: new FormArray(
            answers2stArrray?.length ?
            answers2stArrray :
              [this.buildAutocompleteQuestion()]
            , getQuestionListsValidator
          )
        });
      case QuestionTypesEnum.GRID:
      case QuestionTypesEnum.LIST:
        return this.fb.group({
          answers: new FormArray(
            answers.map((answer) => {
              const answerForm = this.buildAutocompleteQuestion();
              answerForm.get('groupName').patchValue(answer['groupName']);
              answerForm.get('id').patchValue(answer['id']);
              answerForm.get('imageId').patchValue(answer['imageId']);
              answerForm.get('imageUrl').patchValue(answer['imageUrl']);
              answerForm.get('text').patchValue(answer['text']);
              return answerForm;
            }), questionListValidator
          )
        });
      case QuestionTypesEnum.SCORE:
      case QuestionTypesEnum.SCORE_PLUS:
        break;
      case QuestionTypesEnum.OPTIONS:
        return this.fb.group({
          answers: new FormArray(
            answers.map((answer) => {
              const answerForm = this.buildAutocompleteQuestion();
              answerForm.get('groupName').patchValue(answer['groupName']);
              answerForm.get('id').patchValue(answer['id']);
              answerForm.get('imageId').patchValue(answer['imageId']);
              answerForm.get('imageUrl').patchValue(answer['imageUrl']);
              answerForm.get('text').patchValue(answer['text']);
              return answerForm;
            }), questionOptionsValidator
          )
          });
    }
  }

  fillQuestionForm(question, questionForm: FormGroup, roundStatus: RoundStatusEnum) {
    questionForm.get('type').patchValue(question.type);
    if (roundStatus === RoundStatusEnum.OPENED) {
      questionForm.get('type').disable();
    }
    if (questionForm.get('text')) {
      questionForm.get('text').patchValue(question.text);
    }
    if (questionForm.get('imageId')) {
      questionForm.get('imageId').patchValue(question.imageId);
    }

    if (questionForm.get('imageUrl')) {
      questionForm.get('imageUrl').patchValue(question.imageUrl);
    }

    if (questionForm.get('isMain')) {
      questionForm.get('isMain').patchValue(question.isMain);
    }

    if (questionForm.get('sportEvent')) {
      questionForm.get('sportEvent').patchValue(question.sportEvent);
      questionForm.get('sportEvent').disable();
    }
    if (this.isQuestionWithAnswerGroup(question.type) ) {
      if (question.type === QuestionTypesEnum.LISTS) {
        questionForm.get('answerGroupId2').patchValue(question.answerGroup2?.id);
      }
      questionForm.get('answerGroupId').patchValue(question.answerGroup?.id);
    }
    questionForm.get('id').patchValue(question.id);
    questionForm.get('id').disable();

    if (questionForm.get("additional")) {
      questionForm.removeControl('additional');
    }

    if(question.type === QuestionTypesEnum.SCORE) return;

    if (question.type === QuestionTypesEnum.RANGE) {
      const rangeForm = this.buildRangeQuestion();
      rangeForm.get('minValue').patchValue(question.attributes.minValue);
      rangeForm.get('maxValue').patchValue(question.attributes.maxValue);
      rangeForm.get('displayValue').patchValue(question.attributes.displayValue);
      rangeForm.get('incrementValue').patchValue(question.attributes.incrementValue);
      questionForm.addControl('additional', rangeForm);
      return;
    }

    if (question.type === QuestionTypesEnum.SCORE_PLUS) {
      const scorePlusForm = this.buildScorePlusQuestion();
      const maxScoreValue = question.maxScoreValue ? parseInt(question.maxScoreValue.slice(0, -1)) : null;
      scorePlusForm.get('maxScoreValue').patchValue(maxScoreValue);
      questionForm.addControl('additional', scorePlusForm);
      return;
    }

    if (question.type === QuestionTypesEnum.LISTS) {
      const {answerGroup, answerGroup2} = question;
      questionForm.addControl('additional', this.fillAnswers(question.type, question.answers, {answerGroup, answerGroup2}));
      return;
    }
    questionForm.addControl('additional', this.fillAnswers(question.type, question.answers));
  }

  changeFormQuestionTypeByRoundType(roundType: RoundTypesEnum, questionForm, isMultiEventRound: boolean) {
    if(roundType === RoundTypesEnum.Other) {
      const questionsControl: FormArray = questionForm?.get("questions");
      for (
        let removeIndex = questionsControl?.controls
          .findIndex(item => this.questionTypeService.isScoreQuestion(item.get('type').value));
        removeIndex != -1;
        removeIndex = questionsControl?.controls
        .findIndex(item => this.questionTypeService.isScoreQuestion(item.get('type').value))
      ) {
        if (!questionsControl.dirty) {
          questionsControl.markAsDirty();
        }
        questionsControl.removeAt(removeIndex);
      }
      if (questionsControl.length === 0) {
        const question = this.buildForm(isMultiEventRound);
        question.get('type').patchValue(QuestionTypesEnum.RANGE);
        question.addControl('additional', this.buildAdditionnalFormByType(QuestionTypesEnum.RANGE));
        questionsControl.push(question)
      }
    }
  }

  async questionChanges(form) {
    await lastValueFrom(
      form.valueChanges.pipe(
        tap(() => {
          this.predictionPrizeSectionService.generateQuestionList(form.getRawValue());
        })
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
